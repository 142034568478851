import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { NavLink,Link } from 'react-router-dom';

const NavigationBar = () => {
  return (
    <Navbar bg="light" className='px-2 px-sm-2 px-md-4 px-lg-5 py-2' expand="md">
      <Navbar.Brand as={Link} to="/" className="logo-text ms-lg-5 brand fw-bold">HASNAIN JAWED</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
        <Nav className='w-50 d-flex justify-content-evenly' style={{ width: '45px' }}>
          <Nav.Link as={NavLink} to="/about">About</Nav.Link>
          <Nav.Link as={NavLink} to="/works">Work</Nav.Link>
          <Nav.Link as={NavLink} to="/academia">Academia</Nav.Link>
          <Nav.Link as={NavLink} to="/kaleidoscope">Kaleidoscope</Nav.Link>
          <Nav.Link as={NavLink} to="/contact">Contact</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavigationBar;
