import { Helmet } from 'react-helmet';
import Footer from '../Components/Footer';

function Privacy() {
  return (
    <div>
      <Helmet>
        <title>Hasnain Jawed - Privacy Policy</title>
        <meta
          name="description"
          content="Privacy Policy of HasnainJawed.com - Architectural Designer. User Data is Protected"
        />
        <meta
          name="keywords"
          content="DHA Lahore, DHA Islamabad, 1 Kanal, 10 Marla, Bahria Town, LDA, Overseas Block, Zameen, Bahria Town, Zameen.com, LUMS Architecture, Software House interior, Top Architects in Lahore, Top Architects in Lahore, Top Architects"
        />
        <link rel="canonical" href="https://www.hasnainjawed.com/privacy" />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Hasnain Jawed" />

        {/*Social Media Tags*/}
        <meta property="og:title" content="Hasnain Jawed - News" />
        <meta property="og:description" content="Privacy Policy of HasnainJawed.com - Architectural Designer. User Data is Protected" />
      </Helmet>
      <section id="privacy">
        <div className="container">
          <div className="row">

            <div className="wow fadeInUp col-md-4 col-sm-5" data-wow-delay="1.3s">
              <h1>Privacy Policy</h1>
              <p>By www.hasnainjawed.com. User Data is Protected</p>
            </div>

            <div className="wow fadeInUp col-md-7 col-sm-7" data-wow-delay="1.6s">
              <h3>1. Information We Collect</h3>
              <p data-wow-delay="2s">We collect the following information:</p>
              <p data-wow-delay="2s">1.1. <b>User Names and Email Addresses:</b> To provide you with a personalized experience and respond to your inquiries, we may ask for your name and email address.</p>
              <p data-wow-delay="2s">1.2. <b>Purposes and Queries:</b> We also gather information about the reasons for using our service and any questions or concerns you may have. This helps us serve you better.</p>
              <h3>2. How We Use Your Information</h3>
              <p data-wow-delay="2s">2.1. <b>User Communication:</b> We'll use your email address to communicate with you regarding your inquiries or requests. Don't worry, we won't spam you – we'll only contact you if you've asked us to.</p>
              <p data-wow-delay="2s">2.2. <b>Push Notifications:</b> If you opt-in, we'll send you push notifications to keep you updated and informed about our service.</p>
              <p data-wow-delay="2s">2.3. <b>Service Improvement:</b> Your data helps us enhance our service and cater to your needs better.</p>
              <h3>3. Sharing Your Information</h3>
              <p data-wow-delay="2s">Your data is safe with us. We do not sell, trade, or share your personal information with any third parties.</p>
              <h3>4. Your Choices</h3>
              <p data-wow-delay="2s"><b>Opt-Out:</b> If you don't wish to receive push notifications or other communication from us, you can easily manage your preferences in your device settings or let us know.</p>
            </div>

          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
}

export default Privacy
