import { Helmet } from 'react-helmet';
import Footer from '../Components/Footer';
import Portfolio from '../Components/Portfolio';

const commonTextStyle: React.CSSProperties = {
    fontSize: 20,
    textAlign: 'justify',
    width: '100%',
    paddingLeft: '200px',
    paddingRight: '200px',
};

function Demo() {
    return (
        <div className="demo-container">
         <Helmet>
                <title>Hasnain Jawed - Works</title>
                <meta
                    name="description"
                    content="Hasnain Jawed - Architectural Designer, Work"
                />
                <meta
                    name="keywords"
                    content="DHA Lahore, DHA Islamabad, 1 Kanal, 10 Marla, Bahria Town, LDA, Overseas Block, Zameen, Bahria Town, Zameen.com, LUMS Architecture, Software House interior, Top Architects in Lahore, Top Architects in Lahore, Top Architects"
                />
                <link rel="canonical" href="https://www.hasnainjawed.com/works" />
                <meta name="robots" content="index, follow" />
                <meta name="author" content="Hasnain Jawed" />

                {/*Social Media Tags*/}
                <meta property="og:title" content="Hasnain Jawed - Works" />
                <meta property="og:description" content="Hasnain Jawed - Architectural Designer, Works" />
            </Helmet>
            {/* <Portfolio /> */}

            <div className='demo-total'>
                {/* Div 1 */}
                <div>
                    <p className='demo-title'>Title Project</p>
                    <p className='demo-text-first'>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam non urna ac libero interdum aliquet. Duis a libero vel velit tincidunt cursus.
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam non urna ac libero interdum aliquet. Duis a libero vel velit tincidunt cursus.
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam non urna ac libero interdum aliquet. Duis a libero vel velit tincidunt cursus.
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam non urna ac libero interdum aliquet. Duis a libero vel velit tincidunt cursus.
                    </p>
                </div>

                {/* Div 2 */}
                <div className='content-container' style={{ marginTop: 20, display: 'flex', alignItems: 'center' }}>
                    {/* Image on the right */}
                    <img
                        className='image'
                        src="https://firebasestorage.googleapis.com/v0/b/hasnain-fcm.appspot.com/o/f9876a52-b27a-46a1-9c8a-a60bc099c7da.jpg?alt=media"
                        alt="Square"
                        style={{ width: '70%', height: 'auto', marginLeft: 'auto', maxWidth: '100%' }}
                    />
                </div>

                {/* Div 3 */}
                <div style={{ marginTop: 20, justifyContent: 'space-between' }}>
                    {/* Two images with space between */}
                    <img
                     className='image'
                        src="https://firebasestorage.googleapis.com/v0/b/hasnain-fcm.appspot.com/o/eeed5341-3c2c-4058-ab93-a8979f6e3b1d.jpg?alt=media"
                        alt="Image1"
                        style={{ width: '50%', height: 'auto', maxWidth: '100%', paddingRight: 15 }}
                    />
                    <img
                     className='image'
                        src="https://firebasestorage.googleapis.com/v0/b/hasnain-fcm.appspot.com/o/7c305532-c8f7-434a-8a70-d6e4d54fda62.jpg?alt=media"
                        alt="Image2"
                        style={{ width: '50%', height: 'auto', maxWidth: '100%', paddingLeft: 15 }}
                    />
                </div>

                {/* Div 4 */}
                 <div>
                 <p className='demo-text' >
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam non urna ac libero interdum aliquet. Duis a libero vel velit tincidunt cursus.
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam non urna ac libero interdum aliquet. Duis a libero vel velit tincidunt cursus.
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam non urna ac libero interdum aliquet. Duis a libero vel velit tincidunt cursus.
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam non urna ac libero interdum aliquet. Duis a libero vel velit tincidunt cursus.
                    </p>
                </div>

                {/* Div 5 */}
                <div style={{ marginTop: 20, display: 'flex', alignItems: 'center' }}>
                    {/* Image on the left */}
                    <img
                     className='image'
                        src="https://firebasestorage.googleapis.com/v0/b/hasnain-fcm.appspot.com/o/aaa63eab-bccc-4568-82c4-942384d08294.jpg?alt=media"
                        alt="Square"
                        style={{ width: '70%', height: 'auto', marginRight: 'auto', maxWidth: '100%' }}
                    />
                </div>

                {/* Div 6 */}
                <div style={{ marginTop: 20, display: 'flex', alignItems: 'center' }}>
                    {/* Image on the right */}
                    <img
                     className='image'
                        src="https://firebasestorage.googleapis.com/v0/b/hasnain-fcm.appspot.com/o/ba9d3fa4-d9b0-4a23-842d-64377e5d88f6.jpg?alt=media"
                        alt="Square"
                        style={{ width: '70%', height: 'auto', marginLeft: 'auto', maxWidth: '100%' }}
                    />
                </div>


                {/* Div 7 */}
                <div style={{ marginTop:20, justifyContent: 'space-between' }}>
                    {/* Two images with space between */}
                    <img  className='image' src="https://firebasestorage.googleapis.com/v0/b/hasnain-fcm.appspot.com/o/79f940aa-fcf8-4d96-be1f-7b75e13e2c48.jpg?alt=media" alt="Image1" style={{ width: '50%', height: 'auto', maxWidth: '100%', paddingRight: 15 }} />
                    <img  className='image' src="https://firebasestorage.googleapis.com/v0/b/hasnain-fcm.appspot.com/o/90d93af0-5722-445c-801f-e103f95a12d8.jpg?alt=media" alt="Image2" style={{ width: '50%', height: 'auto', maxWidth: '100%', paddingLeft: 15 }} />
                </div>

               {/* Div 8 */}
               <div>
               <p className='demo-text' >
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam non urna ac libero interdum aliquet. Duis a libero vel velit tincidunt cursus.
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam non urna ac libero interdum aliquet. Duis a libero vel velit tincidunt cursus.
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam non urna ac libero interdum aliquet. Duis a libero vel velit tincidunt cursus.
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam non urna ac libero interdum aliquet. Duis a libero vel velit tincidunt cursus.
                    </p>
                </div>

                {/* Div 9 */}
                <div style={{ marginTop: 20, display: 'flex', alignItems: 'center' }}>
                    {/* Image on the right */}
                    <img
                     className='image'
                        src="https://firebasestorage.googleapis.com/v0/b/hasnain-fcm.appspot.com/o/dbab5cb3-823c-4a31-b1de-9fa9752b9ee6.jpg?alt=media"
                        alt="Square"
                        style={{ width: '70%', height: 'auto', marginLeft: 'auto', maxWidth: '100%' }}
                    />
                </div>

                {/* Div 10 */}
                <div style={{ marginTop: 20, display: 'flex', alignItems: 'center' }}>
                    {/* Image on the left */}
                    <img
                     className='image'
                        src="https://firebasestorage.googleapis.com/v0/b/hasnain-fcm.appspot.com/o/294c7309-d9a4-4cd7-92a4-8b0e7632892a.jpg?alt=media"
                        alt="Square"
                        style={{ width: '70%', height: 'auto', marginRight: 'auto', maxWidth: '100%' }}
                    />
                </div>

                {/* Div 11 */}
                <div style={{ marginTop: 20, justifyContent: 'space-between' }}>
                    {/* Two images with space between */}
                    <img
                     className='image'
                        src="https://firebasestorage.googleapis.com/v0/b/hasnain-fcm.appspot.com/o/90f25b37-e8ac-4883-9304-f96abc90eaa5.jpg?alt=media"
                        alt="Image1"
                        style={{ width: '50%', height: 'auto', maxWidth: '100%', paddingRight: 15 }}
                    />
                    <img
                     className='image'
                        src="https://firebasestorage.googleapis.com/v0/b/hasnain-fcm.appspot.com/o/e7f32f7a-17bc-4550-8865-b134ad57f6a2.jpg?alt=media"
                        alt="Image2"
                        style={{ width: '50%', height: 'auto', maxWidth: '100%', paddingLeft: 15 }}
                    />
                </div>

                {/* Div 12 */}
                <div style={{ padding: '50px 0' }}>
                    <p style={{ marginBottom: '10px', fontSize: 20 }}><b>Done in collaboration with IAA</b></p>
                    <p style={{ marginBottom: '0px', fontSize: 20 }}>Type: Housing & Residence</p>
                    <p style={{ marginBottom: '0px', fontSize: 20 }}>Area: 10000 sft</p>
                    <p style={{ marginBottom: '0px', fontSize: 20 }}>Stages: 1-5</p>
                    <p style={{ marginBottom: '0px', fontSize: 20 }}>Scope: Concept Design, Technical Design, Top Supervision</p>
                    <p style={{ marginBottom: '0px', fontSize: 20 }}>Status: Built</p>
                </div>



            </div>
            <Footer />
        </div>
    );
}
export default Demo