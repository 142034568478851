import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Footer from '../Components/Footer';
import { BASE_URL } from '../config';

interface AcademiaAbout {
  description: string;
}

function About() {
  const [academiaAboutData, setAcademiaAboutData] = useState<AcademiaAbout | null>(null);

  useEffect(() => {
    // Fetch data from the API
    fetch(`${BASE_URL}/AcademiaAbout/About`)
      .then(response => response.json())
      .then((data: AcademiaAbout) => setAcademiaAboutData(data))
      .catch(error => console.error('Error fetching data:', error));
  }, []); // Empty dependency array means the effect runs once after the initial render
  return (
    <div>
      <Helmet>
        <title>Hasnain Jawed - About</title>
        <meta
          name="description"
          content="About Hasnain Jawed - BIM Architect with career spanning over 6 years. Skilled in Design, Technology, and seamless project delivery. I thrive on solving intricate design challenges so that the quality of resultant built environments is elevated."
        />
        <meta
          name="keywords"
          content="DHA Lahore, DHA Islamabad, 1 Kanal, 10 Marla, Bahria Town, LDA, Overseas Block, Zameen, Bahria Town, Zameen.com, LUMS Architecture, Software House interior, Top Architects in Lahore, Top Architects in Lahore, Top Architects"
        />
        <link rel="canonical" href="https://www.hasnainjawed.com/about" />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Hasnain Jawed" />
        {/*Social Media Tags*/}
        <meta property="og:title" content="Hasnain Jawed - About" />
        <meta property="og:description" content="About Hasnain Jawed - BIM Architect with career spanning over 6 years. Skilled in Design, Technology, and seamless project delivery. I thrive on solving intricate design challenges so that the quality of resultant built environments is elevated." />
      </Helmet>
      <section id="about">
        <div className="container">
          <div className="row">

            <div className="wow fadeInUp col-md-4 col-sm-5" data-wow-delay="1.3s">
              <img src="/PortfolioImgs/Hasnain.jpg" className="img-responsive" alt="About" />

              <p>
                <b>Architectural Designer</b>
                <br />
                Associate Design Director
                <br />
                <a href="https://www.instagram.com/iaa_studio/" className="custom-link" target="_blank" rel="noopener noreferrer">
                  IAA (Pvt. Ltd.) Lahore, Pakistan
                </a>
              </p>

            </div>

            <div className="wow fadeInUp col-md-7 col-sm-7" data-wow-delay="1.6s">
              {/* Render API data */}
              {academiaAboutData && (
                <div dangerouslySetInnerHTML={{ __html: academiaAboutData.description }} />
              )}
            </div>

          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
}

export default About
