import { Helmet } from 'react-helmet';
import Footer from '../Components/Footer';
import Portfolio from '../Components/Portfolio';

function Works() {
  return (
    <div>
      <Helmet>
        <title>Hasnain Jawed - Works</title>
        <meta
          name="description"
          content="Hasnain Jawed - Architectural Designer, Work"
        />
        <meta
          name="keywords"
          content="DHA Lahore, DHA Islamabad, 1 Kanal, 10 Marla, Bahria Town, LDA, Overseas Block, Zameen, Bahria Town, Zameen.com, LUMS Architecture, Software House interior, Top Architects in Lahore, Top Architects in Lahore, Top Architects"
        />
        <link rel="canonical" href="https://www.hasnainjawed.com/works" />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Hasnain Jawed" />
         
        {/*Social Media Tags*/}
        <meta property="og:title" content="Hasnain Jawed - Works" />
        <meta property="og:description" content="Hasnain Jawed - Architectural Designer, Works" />
      </Helmet>
      <Portfolio />
      <Footer />
    </div>
  );
}

export default Works;
