import React, { useEffect } from 'react';
import Projects from './Projects';
import WOW from 'wowjs';
import 'wowjs/css/libs/animate.css';

function Portfolio() {
  useEffect(() => {
    // Initialize WOW.js
    const wow = new WOW.WOW();
    wow.init();
  }, []);

  return (
    <section id="portfolio">
      <div className="container">
        <div className="row portfolio-section">
          <div className="col-md-12 col-sm-12">
            {/* <!-- iso section --> */}
            <div className="iso-section wow fadeInUp" data-wow-delay="0s">
              {/* Projects Section */}
              <Projects />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Portfolio;
