import React from "react";
import { Helmet } from 'react-helmet';
import Header from "../Components/Header";
import Portfolio from "../Components/Portfolio";
import Footer from "../Components/Footer";

function Home() {
  return (
    <div>
      <Helmet>

        <title>Hasnain Jawed - Architectural Designer</title>
        <meta
          name="description"
          content="Remote Architectural Design Services."
        />
        <meta
          name="keywords"
          content="DHA Lahore, DHA Islamabad, 1 Kanal, 10 Marla, Bahria Town, LDA, Overseas Block, Zameen, Bahria Town, Zameen.com, LUMS Architecture, Software House interior, Top Remote Architects, Hybrid Architects, Architects & Interior Designer, Top Architects in Lahore, Top Architects"
        />
        <link rel="canonical" href="https://www.hasnainjawed.com/" />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Hasnain Jawed" />

        {/*Social Media Tags*/}
        <meta property="og:title" content="Hasnain Jawed - Architectural Designer" />
        <meta property="og:description" content="Remote Architectural Design Services" />
      </Helmet>
      <Header />
      {/* <Portfolio /> */}
      <Footer />
    </div>
  );
}

export default Home;
