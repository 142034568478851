import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Footer from '../Components/Footer';
import { BASE_URL } from '../config';

// Define the interface for Academia data
interface Academia {
  title: string;
  description: string;
}
function Academia() {
  const [academiaData, setAcademiaData] = useState<Academia[]>([]);

  useEffect(() => {
    // Fetch data from the API
    fetch(`${BASE_URL}/AcademiaAbout/AllAcademia`)
      .then(response => response.json())
      .then((data: Academia[]) => setAcademiaData(data))
      .catch(error => console.error('Error fetching data:', error));
  }, []); // Empty dependency array means the effect runs once after the initial render

  return (
    <div>
      <Helmet>
        <title>Hasnain Jawed - Academia</title>
        <meta
          name="description"
          content="Academia Hasnain Jawed - BIM Architect with career spanning over 6 years. Skilled in Design, Technology, and seamless project delivery. I thrive on solving intricate design challenges so that the quality of resultant built environments is elevated."
        />
        <meta
          name="keywords"
          content="DHA Lahore, DHA Islamabad, 1 Kanal, 10 Marla, Bahria Town, LDA, Overseas Block, Zameen, Bahria Town, Zameen.com, LUMS Architecture, Software House interior, Top Architects in Lahore, Top Architects in Lahore, Top Architects"
        />
        <link rel="canonical" href="https://www.hasnainjawed.com/Academia" />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Hasnain Jawed" />
        {/*Social Media Tags*/}
        <meta property="og:title" content="Hasnain Jawed - Academia" />
        <meta property="og:description" content="Academia Hasnain Jawed - BIM Architect with career spanning over 6 years. Skilled in Design, Technology, and seamless project delivery. I thrive on solving intricate design challenges so that the quality of resultant built environments is elevated." />
      </Helmet>
      <section id="about">
        <div className="container">
          <div className="row">

            {/* Center this Div */}
            <div className="wow fadeInUp col-md-7 col-sm-7 d-flex flex-column  text-center mx-auto" data-wow-delay="1.6s">
              {/* Description */}
              {academiaData.map(item => (
                <div key={item.title}>
                  <h3 className="capitalize-first-letter">{item.title}</h3>
                  {/* Render HTML content using dangerouslySetInnerHTML */}
                  <div data-wow-delay="2s" style={{ color: '#777' }} dangerouslySetInnerHTML={{ __html: item.description }} />
                  <hr />
                </div>
              ))}
            </div>

          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
}

export default Academia
