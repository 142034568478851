import React, { useEffect, useState } from "react";
import WOW from "wowjs";
import "wowjs/css/libs/animate.css";
import { format } from "date-fns";
import { BASE_URL } from '../config';

function NewsBox() {
  const [newsData, setNewsData] = useState([]);

  useEffect(() => {
    // Initialize WOW.js
    const wow = new WOW.WOW();
    wow.init();

    fetch(`${BASE_URL}/news/allnews`)
    .then(async (response) => {
      if (response.status === 404) {
        return [];
      } else {
        const data = await response.json();
        setNewsData(data); // Store API response in state
        return data;
      }
    })
    .catch((error) => {
      console.error("Error fetching news data:", error);
    });
}, []);

  // Function to format the date
  const formatDate = (dateString) => {
    const formattedDate = format(new Date(dateString), "d MMMM y, EEEE");
    return formattedDate;
  };

  // Render the first two divs with the last two elements from the API response
  const firstTwoNews = newsData.slice(-2).map((news) => (
    <div
      key={news.newsId}
      className="wow fadeInUp col-md-6 col-sm-6"
      data-wow-delay="2s"
    >
      {/* Render the news data here */}
      <div className="blog-thumb" data-wow-delay="2s">
        <img
          src={news.file}
          className="img-responsive"
          alt="Blog"
          style={{ width: "450px", height: "450px" }}
        />
        <h1>{news.title}</h1>
        <div className="post-format">
          <span>By Hasnain Jawed</span>
          <span>
            <i className="fa fa-date"></i>
            {formatDate(news.date)}
          </span>
        </div>
        <p>{news.description}</p>
        <a href={`/newsdetails/${news.newsId}`} className="btn btn-default">
          Full Article
        </a>
      </div>
    </div>
  ));

  // Render the remaining divs with the rest of the elements using a loop
  const remainingNews = newsData.slice(0, -2).map((news) => (
    <div
      key={news.newsId}
      className="wow fadeInUp col-md-4 col-sm-4"
      data-wow-delay="2s"
    >
      {/* Render the news data here */}
      <div className="blog-thumb">
        <img
          src={news.file}
          className="img-responsive"
          alt="Blog"
          style={{ width: "356px", height: "356px" }}
        />
        <h1>{news.title}</h1>
        <div className="post-format">
          <span>
            <i className="fa fa-date"></i>
            {formatDate(news.date)}
          </span>
        </div>
        <p>{news.description}</p>
        <a href={`/newsdetails/${news.newsId}`} className="btn btn-default">
          Details
        </a>
      </div>
    </div>
  ));

  return (
    <section id="blog">
      <div className="container">
        <div className="row">
          {/* Render the first two divs */}
          {firstTwoNews}

          {/* Render the remaining divs using a loop */}
          {remainingNews}
        </div>
      </div>
    </section>
  );
}

export default NewsBox;
