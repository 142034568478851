import React, { useEffect } from 'react';
import WOW from 'wowjs';
import { BiLogoFacebook, BiLogoWhatsapp } from 'react-icons/bi';
import { AiFillInstagram, AiFillLinkedin, AiFillMail } from 'react-icons/ai';
import { Link } from 'react-router-dom';

function Footer() {
  useEffect(() => {
    // Initialize WOW.js
    const wow = new WOW.WOW();
    wow.init();
  }, []);

  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          {/* Mobile Design */}
          <div className="col-md-12 col-sm-12 d-sm-block d-md-none">
            <p className="wow fadeInUp" data-wow-delay="0s">
              Copyright © 2023 Hasnain Jawed - <Link style={{ color: 'inherit', textDecoration: 'none' }} to='/privacy'>Privacy</Link>
            </p>
            <ul className="social-icon wow fadeInUp" data-wow-delay="0s">
              <li><Link target='_blank' to='https://www.linkedin.com/in/hasnain-jawed-/'><AiFillLinkedin size={30} /></Link></li>
              <li><Link target='_blank' to='mailto:H.jawed@outlook.com'><AiFillMail size={30} /></Link></li>
              <li><Link target='_blank' to='https://wa.me/923456449109'><BiLogoWhatsapp size={30} /></Link></li>
              <li><Link target='_blank' to='https://www.instagram.com/hasnain.jawed_/'> <AiFillInstagram size={30} /></Link></li>
              <li><Link target='_blank' to='https://www.facebook.com/hasnain.jawed1'><BiLogoFacebook size={30} /></Link></li>
            </ul>
          </div>
          
          {/* Desktop Design */}
          <div className="col-md-12 col-sm-12 d-flex justify-content-between align-items-center d-none d-md-flex">
            <p className="wow fadeInUp" data-wow-delay="0s">
              Copyright © 2023 Hasnain Jawed - <Link style={{ color: 'inherit', textDecoration: 'none' }} to='/privacy'>Privacy</Link>
            </p>
            <ul className="social-icon wow fadeInUp" data-wow-delay="0s">
              <li><Link target='_blank' to='https://www.linkedin.com/in/hasnain-jawed-/'><AiFillLinkedin size={30} /></Link></li>
              <li><Link target='_blank' to='mailto:H.jawed@outlook.com'><AiFillMail size={30} /></Link></li>
              <li><Link target='_blank' to='https://wa.me/923456449109'><BiLogoWhatsapp size={30} /></Link></li>
              <li><Link target='_blank' to='https://www.instagram.com/hasnain.jawed_/'> <AiFillInstagram size={30} /></Link></li>
              <li><Link target='_blank' to='https://www.facebook.com/hasnain.jawed1'><BiLogoFacebook size={30} /></Link></li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
