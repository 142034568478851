import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Footer from '../Components/Footer';
import { useParams } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-image-gallery/styles/css/image-gallery.css';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { BASE_URL } from '../config';

interface ProjectFile {
  imageID: number;
  imageName: string;
  // Add any other properties if necessary
}

function WorkDetails() {
  const navigate = useNavigate(); // Initialize useNavigate hook
  const { slug } = useParams(); // Use slug instead of id

  const { id } = useParams();
  const [projectData, setProjectData] = useState<{
    title: string | null;
    description: string | null;
    completed: string | null;
    category: string | null;
    client: string | null;
    cost: string | null;
    area: string | null;
    scope: string | null;
    stages: string | null;
    files: ProjectFile[]; // Specify the type as an array of ProjectFile
  }>({
    title: null,
    description: null,
    completed: null,
    category: null,
    client: null,
    cost: null,
    area: null,
    scope: null,
    stages: null,
    files: [],
  } as {
    title: string | null;
    description: string | null;
    completed: string | null;
    category: string | null;
    client: string | null;
    cost: string | null;
    area: string | null;
    scope: string | null;
    stages: string | null;
    files: ProjectFile[];
  });

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch project details using the id from the API
    console.log('Slug:', slug);
    fetch(`${BASE_URL}/project/GetProjectDetails/${slug}`)
      .then(response => {
        if (response.status === 404) {
          throw new Error('Not Found');
        }

        return response.json();
      })
      .then(data => {
        console.log(data.status);
        setProjectData(data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching project data:', error);
        setLoading(false);
        navigate('/notfound');
        return null;
      });
  }, [id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  const getDynamicImageSize = (index: number) => {
    if (index % 4 === 0 || index % 4 === 3) {
      return '1000px';
    } else {
      return '368px';
    }
  };


  return (
    <div>
      <Helmet>
        <title>{projectData.title}</title>
        <meta
          name="description"
          content="Hasnain Jawed - BIM Architect, Work"
        />
        <meta
          name="keywords"
          content="DHA Lahore, DHA Islamabad, 1 Kanal, 10 Marla, Bahria Town, LDA, Overseas Block, Zameen, Bahria Town, Zameen.com, LUMS Architecture, Software House interior, Top Architects in Lahore, Top Architects in Lahore, Top Architects"
        />
        <link rel="canonical" href={`https://www.hasnainjawed.com/workdetails/${slug}`} />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Hasnain Jawed" />

        {/*Social Media Tags*/}
        <meta property="og:title" content="Hasnain Jawed - Works" />
        <meta property="og:description" content="Hasnain Jawed - BIM Architect, Works" />
      </Helmet>
      <section id="single-project">
        <div className="container">
          <div className="row">
            {!loading && (
              <div className="wow fadeInUp col-md-offset-1 col-md-3 col-sm-offset-1 col-sm-4" data-wow-delay="0s">
                {/* Category */}
                <div className="project-info">
                  {projectData.category !== null && (
                    <>
                      <h4 style={{ fontSize: '20px' }}>Category</h4>
                      <p style={{ fontSize: '14px' }}>{projectData.category}</p>
                    </>
                  )}
                </div>
                {/* Status */}
                <div className="project-info">
                  {projectData.completed !== null && (
                    <>
                      <h4 style={{ fontSize: '20px' }}>Status</h4>
                      <p style={{ fontSize: '14px' }}>{projectData.completed}</p>
                    </>
                  )}
                </div>
                {/* Scope */}
                <div className="project-info">
                  {projectData.scope !== null && (
                    <>
                      <h4 style={{ fontSize: '20px' }}>Scope</h4>
                      <p style={{ fontSize: '14px' }}>{projectData.scope}</p>
                    </>
                  )}
                </div>
                {/* Client */}
                <div className="project-info">
                  {projectData.client !== null && (
                    <>
                      <h4 style={{ fontSize: '20px' }}>Client</h4>
                      <p style={{ fontSize: '14px' }}>{projectData.client}</p>
                    </>
                  )}
                </div>
                {/* Area */}
                <div className="project-info">
                  {projectData.area !== null && (
                    <>
                      <h4 style={{ fontSize: '20px' }}>Area</h4>
                      <p style={{ fontSize: '14px' }}>{projectData.area}</p>
                    </>
                  )}
                </div>
                {/* Cost */}
                <div className="project-info">
                  {projectData.cost !== null && (
                    <>
                      <h4 style={{ fontSize: '20px' }}>Cost</h4>
                      <p style={{ fontSize: '14px' }}>{projectData.cost}</p>
                    </>
                  )}
                </div>
                {/* Stages */}
                <div className="project-info">
                  {projectData.stages !== null && (
                    <>
                      <h4 style={{ fontSize: '20px' }}>Stages</h4>
                      <p style={{ fontSize: '14px' }}>{projectData.stages}</p>
                    </>
                  )}
                </div>

              </div>
            )}
            {!loading && projectData.files && projectData.files.length > 0 && (
              <div className="wow fadeInUp col-md-7 col-sm-7" data-wow-delay="1s">
                {/* Project title and image gallery */}
                <div>
                  <h1>{projectData.title}</h1>
                  {/* <div className="custom-image-gallery"> */}
                  <div className="image-feed">
                    {projectData.files.map((imageUrl, index: number) => (
                      <div key={index} className="image-item">
                        <img
                          src={imageUrl.imageName}
                          alt={`Project ${index + 1}`}
                          style={{
                            width: getDynamicImageSize(index),
                            height: 'auto',
                            display: index % 4 === 2 ? 'block' : 'inline-block',
                            marginRight: index % 4 === 2 ? '0' : '10px',
                          }}
                        />
                      </div>
                    ))}
                  </div>
                  {/* </div> */}
                  <br></br>
                  <p>{projectData.description}</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default WorkDetails;
