import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { BASE_URL } from '../config';


function Header() {
  const location = useLocation();
  const activePage = location.pathname === '/' ? 'home' : location.pathname.substring(1);

  const [backgroundIndex, setBackgroundIndex] = useState(1); // Start with the first image

  useEffect(() => {
    const interval = setInterval(() => {
      setBackgroundIndex((prevIndex) => (prevIndex % 6) + 1); // Cycle through 1 to 5
    }, 3000); // Change background every 3 seconds (adjust the interval as needed)

    // Fetch Categories API
    fetch(`${BASE_URL}/project/allcategouries`)
      .then(async response => {
        if (response.status === 404) {
          // Handle 404 error by setting categories state to null
          return [];
        } else {
          const data = await response.json();
          return data;
        }
      })
      .catch(error => {
        console.error('Error fetching categories:', error);
      });

    return () => clearInterval(interval); // Clear the interval on component unmount
  }, []);

  const headerClassName = `header-home image-${backgroundIndex}`;

  return (
    <header id="header" className={headerClassName}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            {/* Add content here if needed */}
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
