import React from 'react';

interface Project {
  projectId: number;
  title: string;
  file: string;
  categoury: string; // Corrected spelling to 'categoury'
}

const FilterList: React.FC<{ handlderFilterList: (e: React.MouseEvent<HTMLButtonElement>) => void; categories: Project[] }> = ({ handlderFilterList, categories }) => {
  return (
    <ul className="filter-wrapper clearfix">
      <li>
        <button value="All" onClick={handlderFilterList} className="selected">
          All
        </button>
      </li>
      {categories.map((category, index) => (
        <li key={index}>
          <button value={category.categoury} onClick={handlderFilterList}>
            {category.categoury}
          </button>
        </li>
      ))}
    </ul>
  );
};

export default FilterList;
