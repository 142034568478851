import React, { useEffect } from "react";
import { messaging } from "./firebase";
import { getToken } from "@firebase/messaging";
import NavigationBar from "./Components/NavigationBar";
import { Route, Routes, Navigate } from "react-router-dom";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import Kaleidoscope from "./Pages/Kaleidoscope";
import Works from "./Pages/Works";
import NotFound from "./Pages/NotFound";
import NewsDetails from "./Pages/NewsDetail";
import WorkDetails from "./Pages/WorkDetail";
import Academia from "./Pages/Academia";
import { BASE_URL } from "./config";
import Privacy from "./Pages/Privacy";
import Demo from "./Pages/Demo";

function App() {
  async function requestPermission() {
    try {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        // Generate Token
        const token = await getToken(messaging, {
          vapidKey:
            "BCuTnnZbWOH-sqLpUJx366lbZAFgpo8prE1-0xU9z6LIP1uaI2uad3c8OeBuxJdxuH9SLquyJ3P5Gw_Qi3Q92IU",
        });
        sendTokenToServer(token);
        //console.log("Token Gen", token);
      } else if (permission === "denied") {
        console.log("You denied permission for notifications");
      }
    } catch (error) {
      console.error("Error requesting permission or generating token:", error);
    }
  }

  async function sendTokenToServer(token) {
    try {
      // Make an HTTP POST request to your server to send the token
      const response = await fetch(`${BASE_URL}/token/GetToken?token=${token}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token }), // Send the token in the request body
      });

      if (response.ok) {
        console.log("Token sent to the server successfully.");
      } else {
        console.error("Failed to send token to the server.");
      }
    } catch (error) {
      console.error("Error sending token to the server:", error);
    }
  }

  useEffect(() => {
    requestPermission();
  }, []);

  return (
    <div>
      <NavigationBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/works" element={<Works />} />
        <Route path="/academia" element={<Academia />} />
        <Route path="/kaleidoscope" element={<Kaleidoscope />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/newsdetails/:id" element={<NewsDetails />} />
        <Route path="/workdetails/:slug" element={<WorkDetails />} />
        <Route path="/notfound" element={<NotFound />} />
        <Route path="/privacy" element={<Privacy/>}/>
        <Route path="/demo" element={<Demo/>}/>
        <Route path="*" element={<Navigate to="/notfound" />} />
      </Routes>
    </div>
  );
}

export default App;
