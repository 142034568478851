import React from 'react'
import Header from '../Components/Header'
import { Helmet } from 'react-helmet';
import Footer from '../Components/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AiFillMail, AiFillPhone, AiOutlineGlobal, } from 'react-icons/ai';

function Contact() {
  return (
    <div>
      <Helmet>
        <title>Hasnain Jawed - Contact</title>
        <meta
          name="description"
          content="Contact Hasnain Jawed - Architectural Designer, LinkedIn, Facebook, Instagram & etc. Email: H.jawed@outlook.com"
        />
        <meta
          name="keywords"
          content="DHA Lahore, DHA Islamabad, 1 Kanal, 10 Marla, Bahria Town, LDA, Overseas Block, Zameen, Bahria Town, Zameen.com, LUMS Architecture, Software House interior, Top Architects in Lahore, Top Architects in Lahore, Top Architects"
        />
        <link rel="canonical" href="https://www.hasnainjawed.com/contact" />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Hasnain Jawed" />
        {/*Social Media Tags*/}
        <meta property="og:title" content="Hasnain Jawed - Contact" />
        <meta property="og:description" content="Contact Hasnain Jawed - Architectural Designer, LinkedIn, Facebook, Instagram & etc. Email: H.jawed@outlook.com" />
      </Helmet>
      {/* <Header /> */}
      <section id="contact">
        <div className="container">
          <div className="row">

            <div className="wow fadeInUp col-md-6 col-sm-12" data-wow-delay="1.3s">
              <div className="google_map">
                <iframe
                  title="Google Map"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d54423.8523347717!2d74.284392862847!3d31.510678149625125!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3919051dc01024bf%3A0xf54f1f4f6667fed1!2sIAA%20-%20Imtiaz%20Ali%20Associates%20(Pvt.%20Ltd.)!5e0!3m2!1sen!2s!4v1692092811948!5m2!1sen!2s"
                  width="100%"
                  height="400"
                  frameBorder="0"
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>

              </div>
            </div>

            <div className="wow fadeInUp col-md-6 col-sm-12" data-wow-delay="1.6s">
              <h1>Let's work together!</h1>
              <div className="contact-form">
                <form id="contact-form" method="post" action="#">
                  <input name="name" type="text" className="form-control" placeholder="Your Name" required />
                  <input name="email" type="email" className="form-control" placeholder="Your Email" required />
                  <textarea name="message" className="form-control" placeholder="Message" required />
                  <div className="contact-submit">
                    <input type="submit" className="form-control submit btn btn-primary" value="Send a message" />
                  </div>
                </form>
              </div>
            </div>

            <div className="clearfix"></div>

            <div className="col-md-4 col-sm-4">
              <div className="wow fadeInUp media" data-wow-delay="0.3s">
                <div className="media-object pull-left">
                  <i className="fa fa-tablet"></i>
                </div>
                <div className="media-body">
                  <h3 className="media-heading"><AiFillPhone size={40} /> Phone</h3>
                  <p>+92 345 6449109</p>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-sm-4">
              <div className="wow fadeInUp media" data-wow-delay="0.6s">
                <div className="media-object pull-left">
                  <i className="fa fa-envelope"></i>
                </div>
                <div className="media-body">
                  <h3 className="media-heading"><AiFillMail size={40} /> Email</h3>
                  <p>H.jawed@outlook.com</p>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-sm-4">
              <div className="wow fadeInUp media" data-wow-delay="0.9s">
                <div className="media-object pull-left">
                  <i className="fa fa-globe"></i>
                </div>
                <div className="media-body">
                  <h3 className="media-heading"><AiOutlineGlobal size={40} /> Address</h3>
                  <p>298-A, Upper Mall Scheme, Lahore</p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
}

export default Contact
