import { Helmet } from 'react-helmet';
import Footer from '../Components/Footer';
import NewsBox from '../Components/NewsBox';

function Kaleidoscope() {
  return (
    <div>
      <Helmet>
        <title>Hasnain Jawed - kaleidoscope</title>
        <meta
          name="description"
          content="Cultural Kaleidoscope By Hasnain Jawed - Architectural Designer"
        />
        <meta
          name="keywords"
          content="DHA Lahore, DHA Islamabad, 1 Kanal, 10 Marla, Bahria Town, LDA, Overseas Block, Zameen, Bahria Town, Zameen.com, LUMS Architecture, Software House interior, Top Architects in Lahore, Top Architects in Lahore, Top Architects"
        />
        <link rel="canonical" href="https://www.hasnainjawed.com/news" />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Hasnain Jawed" />
         
        {/*Social Media Tags*/}
        <meta property="og:title" content="Hasnain Jawed - kaleidoscope" />
        <meta property="og:description" content="Cultural kaleidoscope By Hasnain Jawed - Architectural Designer" />
      </Helmet>
      <NewsBox />
      <Footer />
    </div>
  )
}

export default Kaleidoscope
