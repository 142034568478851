import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Footer from '../Components/Footer';
import { useParams } from 'react-router-dom';
import { BASE_URL } from '../config';
import { useNavigate } from 'react-router-dom';

function NewsDetails() {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [newsData, setNewsData] = useState({
    title: '',
    description: '',
    date: '',
    files: [],
  });

  const navigate = useNavigate();

  useEffect(() => {
    // Fetch news details using the id from the API
    fetch(`${BASE_URL}/news/getnewsdetails?newsId=${id}`)
      .then((response) => {
        if (response.status === 404) {
          throw new Error('Not Found');
        }

        return response.json();
      })
      .then((data) => {
        setNewsData(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching news data:', error);
        setLoading(false);
        navigate('/notfound');
        return null;
      });
  }, [id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  const getDynamicImageSize = (index: number) => {
    if (index % 4 === 0 || index % 4 === 3) {
      return '1000px';
    } else {
      return '368px';
    }
  };




  return (
    <div>
      <Helmet>
        <title>{newsData.title}</title>
        <meta
          name="description"
          content="News By Hasnain Jawed - BIM Architect"
        />
        <meta
          name="keywords"
          content="DHA Lahore, DHA Islamabad, 1 Kanal, 10 Marla, Bahria Town, LDA, Overseas Block, Zameen, Bahria Town, Zameen.com, LUMS Architecture, Software House interior, Top Architects in Lahore, Top Architects in Lahore, Top Architects"
        />
        <link rel="canonical" href="https://www.hasnainjawed.com/news" />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Hasnain Jawed" />
         
        {/*Social Media Tags*/}
        <meta property="og:title" content={newsData.title} />
        <meta property="og:description" content="News By Hasnain Jawed - BIM Architect" />
      </Helmet>
      <section id="single-post">
        <div className="container">
          <div className="row">
            <div
              className="wow fadeInUp col-md-offset-1 col-md-10 col-sm-offset-1 col-sm-10"
              data-wow-delay="2.3s"
            >
              <div className="blog-thumb">
                <h1>{newsData.title}</h1>
                <div className="post-format">
                  <span>By Hasnain Jawed</span>
                  <span>
                    <i className="fa fa-date"></i> {newsData.date}
                  </span>
                </div>
                <p>{newsData.description}</p>
              </div>
              <div className="image-feed">
                    {newsData.files.map((imageUrl, index: number) => (
                      <div key={index} className="image-item">
                        <img
                          src={imageUrl}
                          alt={`Project ${index + 1}`}
                          style={{
                            width: getDynamicImageSize(index),
                            height: 'auto',
                            display: index % 4 === 2 ? 'block' : 'inline-block',
                            marginRight: index % 4 === 2 ? '0' : '10px',
                          }}
                        />
                      </div>
                    ))}
                  </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
}

export default NewsDetails
