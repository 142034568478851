import React from 'react';
import { LiaLinkSolid } from 'react-icons/lia';
import { Link } from 'react-router-dom';

const ProjectBox: React.FC<{ project: any }> = ({ project }) => {
  const { title, file, categoury } = project;

  return (
    <div className={`col-md-4 col-sm-6 iso-box ${categoury}`} key={title}>
      <div className="portfolio-thumb">
        <img src={file} className="img-fluid" alt="Portfolio" style={{ width:'420px', height:'400px'}} />
        <div className="portfolio-overlay">
          <div className="portfolio-item">
            <Link to={`/workdetails/${project.slug}`}>
              <LiaLinkSolid
                size={50}
                color="black"
                style={{
                  padding: '12px',
                  backgroundColor: 'white',
                  borderRadius: '50%',
                  fontWeight: '800 !important',
                }}
              />
            </Link>
            <p
              style={{
                letterSpacing: '0.3rem',
              }}
              className="text-uppercase mt-3 text-light"
            >
              {title}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectBox;
