import React, { useEffect, useState } from 'react';
import ProjectBox from './ProjectBox';
import FilterList from './FilterList';
import WOW from 'wowjs';
import 'wowjs/css/libs/animate.css';
import { BASE_URL } from '../config';


interface Project {
  projectId: number;
  title: string;
  file: string;
  categoury: string; // Corrected spelling to 'categoury'
}

function Projects() {
  const [displayedProjects, setDisplayedProjects] = useState<Project[]>([]);
  const [allProjects, setAllProjects] = useState<Project[]>([]);
  const [categories, setCategories] = useState<Project[]>([]);
  const [loading, setLoading] = useState(true); // New loading state

  useEffect(() => {
    setLoading(true); // Start loading

    // Fetch Projects API
    fetch(`${BASE_URL}/project/allprojects`)
      .then(response => {
        if (response.status === 404) {
          // Handle 404 error by setting displayedProjects to an empty array
          console.log('Projects data: 404 error');
          setDisplayedProjects([]);
          setLoading(false); // Stop loading
          return [];
        }
        return response.json();
      })
      .then(data => {
        setDisplayedProjects(data);
        setAllProjects(data);
        setLoading(false); // Stop loading
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false); // Stop loading
      });

    // Fetch Categories API
    fetch(`${BASE_URL}/project/allcategouries`)
      .then(async response => {
        if (response.status === 404) {
          // Handle 404 error by setting categories state to null
          return [];
        } else {
          const data = await response.json();
          setCategories(data);
          return data;
        }
      })
      .catch(error => {
        console.error('Error fetching categories:', error);
      });

    // Initialize WOW.js
    const wow = new WOW.WOW();
    wow.init();
  }, []);

  function handlderFilterList(e: React.MouseEvent<HTMLButtonElement>) {
    const category = e.currentTarget.value;
    if (category === 'All') {
      setDisplayedProjects(allProjects);
    } else {
      const filteredProjects = allProjects.filter(project => project.categoury === category); // Corrected spelling to 'categoury'
      setDisplayedProjects(filteredProjects);
    }
  }

  const projectsLists = displayedProjects.map((project, index) => (
    <ProjectBox key={index} project={project} />
  ));

  return (
    <>
      <FilterList handlderFilterList={handlderFilterList} categories={categories} />
      <div className="iso-box-section">
        {loading ? (
          <div className="loading-animation"></div> // Use CSS-based loading animation
        ) : (
          <div className="iso-box-wrapper wow fadeInUp" data-wow-delay="0s">
            {projectsLists}
          </div>
        )}
      </div>
    </>
  );
}

export default Projects;
