import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyCMM9jsrscTfcCqMzVFJ5HeBTP0TjeDQCE",
    authDomain: "hasnain-fcm.firebaseapp.com",
    projectId: "hasnain-fcm",
    storageBucket: "hasnain-fcm.appspot.com",
    messagingSenderId: "699590829690",
    appId: "1:699590829690:web:ea4e43f4364b46fda13dc3",
    measurementId: "G-0XXRYNB4YR"
  };

  // Initialize Firebase
const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);